import styled from 'styled-components';

export const StyledMaps = styled.iframe`
  height: 100%;
  width: 100%;
  border: 0;

  @media screen and (max-width: 959px) {
    height: 400px;
  }
`;
