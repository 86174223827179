import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Grid, Container, Button } from '@material-ui/core';

import Layout from '../components/Layout';
import { StyledRibbon } from '../components/Ribbon/StyledRibbon';
import Maps from '../components/Maps';
import SEO from '../components/SEO';

const Contact = ({ path, location }) => {
  const { loodgieter } = useStaticQuery(graphql`
    query {
      loodgieter: file(relativePath: { eq: "bathroom/bathroom-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const seo = {
    title: 'Contact - E S I - Installatietechniek B.V.',
    description:
      'Vragen over mogelijkheden of professioneel advies? Neem gerust contact op met E S I - Installatietechniek B.V.',
    pathname: path,
    location,
  };

  return (
    <>
      <Layout>
        <SEO
          title={seo.title}
          description={seo.description}
          location={location}
        />

        <StyledRibbon>
          <div className='heading'>
            <Img
              loading='auto'
              className='heading__image'
              fluid={loodgieter.childImageSharp.fluid}
              alt='E S I - Installatietechniek B.V.'
            />
            <div className='heading__text'>
              <h1>Contact</h1>
              <h3 className='mb-2x center-xs'>Direct persoonlijk contact</h3>
              <h3>06-42090304</h3>
              <div className='hidden-sm hidden-md hidden-lg'>
                <a href='tel:0642090304'>
                  <Button
                    role='button'
                    variant='contained'
                    className='btn btn--secondary heading__button mt-4x '
                  >
                    <i className='material-icons mr-1x'>phone</i>Krijg advies op
                    maat
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </StyledRibbon>
        <Container fixed>
          <Grid container className='mb-10x mt-10x'>
            <Grid item xs={12} md={6} className='pr-md-4x'>
              <h2>Wie is E S I - Installatietechniek B.V.?</h2>
              <p>
                E S I - Installatietechniek B.V. is actief als installateur in
                de omgeving van Purmerend, Waterland, Amsterdam, Zaanstad, en
                Hoorn. Met jaren lange ervaring op installatiegebied is E S I
                het juiste adres voor klantgerichte service, uitstekende
                adviezen en zeer vakkundig installatiewerk...
              </p>
              <p>
                Onze medewerkers zetten zich iedere dag voor de volle 100% in
                voor een tevreden klant. We bieden een veelomvattend
                dienstenpakket zodat u bijna iedere installatieklus aan onze
                specialisten kunt uitbesteden. Onze installateurs ontzorgen u op
                het gebied van badkamers, leidingwerk, riolering, gas- en
                waterinstallaties, centrale verwarming, ventilatie en duurzame
                oplossingen.
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <Maps className='maps mt-8x mt-sm-0' />
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

Contact.propTypes = {
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Contact;
