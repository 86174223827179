import React from 'react';
import PropTypes from 'prop-types';
import { StyledMaps } from './StyledMaps';

const Maps = ({ className }) => (
  <StyledMaps
    className={className}
    loading='lazy'
    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.096202611373!2d4.994553915964804!3d52.513598044455804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c601fc8fadc299%3A0xe28a43c506773092!2sE%20S%20I%20-%20Installatietechniek!5e0!3m2!1snl!2snl!4v1572941339135!5m2!1snl!2snl'
    frameborder='0'
  />
);

Maps.propTypes = {
  className: PropTypes.string,
};

export default Maps;
